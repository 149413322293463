import App from 'next/app'
import Head from 'next/head'
import { Analytics } from '@vercel/analytics/react';
import Footer from '@/components/Footer'
import Navbar from '@/components/Navbar'
import styles from '@/styles/main.css'

export default function MyApp({ Component, pageProps, metadata, appProps }) {
  return (
	  <div className="HTML">
		<Head>
			<meta name="viewport" content="initial-scale=1.0, width=device-width" />
		</Head>

		<div>
		<Navbar />
		<Component {...pageProps} />
		<Analytics />
		<Footer background_color="#000000"/>
		</div>
  </div>
  )
}
 
MyApp.getInitialProps = async (context) => {
  const ctx = await App.getInitialProps(context)

  return { ...ctx}
}