import Link from 'next/link'
import styles from '@/styles/main.css'
export default function Navbar({PageTitle}) {
	return (<>
		<nav className="Navbar">
			<Link href="/" className="NavbarText">Home</Link>
			<Link href="/about" className="NavbarText">About</Link>
			<Link href="/projects" className="NavbarText">Projects</Link>
			<Link href="/contact" className="NavbarText">Contact</Link>
		</nav>
	</>)
}


// <Link href="/"         className="NavbarText">Home</Link>
// <Link href="/about"    className="NavbarText">About</Link>
// <Link href="/projects" className="NavbarText">Projects</Link>
// <Link href="/contact"  className="NavbarText">Contact</Link>