import styles from '@/styles/main.css'
export default function Footer({background_color}) {
	background_color = background_color || "#1e1e1e"
	const metadata = {
		name: 'Tyler Heshmati',
		link: 'https://www.linkedin.com/in/tylerheshmati/'
	}
	return <footer className="Footer">
	<p className="text-xl text-center">
	  Made with{' '} <span role="img" aria-label="love">❤️</span> by{' '}
	  <a href={metadata.link} target="_blank" rel="noopener noreferrer" className="FooterLink" > {metadata.name} </a>
	  © {new Date().getFullYear()}
	</p>

	<style jsx>{`
	.Footer {
		background-color: ${background_color};
	}
	`}</style>

  </footer>
  }